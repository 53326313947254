import styled from "styled-components";

const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryDarkbg};
  width: 100%;
  padding: 2rem 0;
  color: ${({ theme }) => theme.colors.primary};
  @media ${({ theme }) => theme.breakpoints.device.l} {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  @media ${({ theme }) => theme.breakpoints.device.m} {
    padding: 2rem;
  }
`;

export default StyledFooter;
