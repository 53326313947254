import React from "react";
import CopyText from "../CopyText";
import FlexContainer from "../FlexContainer";
import Headline from "../Headline";

const PrivacyPolicy = () => {
  return (
    <FlexContainer
      direction="column"
      background="backgroundGreyTransparent"
      padding="2rem 1.5rem"
      alignItems="center"
    >
      {" "}
      <FlexContainer maxWidth="1000px" direction="column">
        <Headline level={3} text="Who we are" margin="0 0 1.4rem 0" />
        <CopyText margin="0 0 1.5rem 0">
          Our website address is: https://uniiicy.com
        </CopyText>
        <Headline level={3} text="Scope of Application" margin="0 0 1.4rem 0" />
        <CopyText>
          This data protection declaration is intended to inform the users of
          this website in accordance with the General Data Protection Regulation
          about the type, scope and purpose of the collection and use of
          personal data by the website operator Uniicy Limited,
          info[at]uniicy.com (thereafter website operator). The website operator
          takes your data protection very seriously and treats your personal
          data confidentially and in accordance with legal regulations. Keep in
          mind that data transmission over the Internet can be subject to
          security vulnerabilities. Complete protection against access by third
          parties is not possible.
        </CopyText>
        <Headline level={3} text="Access Data" margin="0 0 1.4rem 0" />
        <CopyText margin="0 0 1.5rem 0">
          The website operator or page provider collects data about access to
          the page and saves it as “server log files”. The following data is
          logged in this way: Visited Website Time at time of access Amount of
          data sent in bytes Source/reference from which you accessed the page
          Browser used Operating system used IP address used The data collected
          is used solely for statistical evaluations and to improve the website.
          However, the website operator reserves the right to subsequently check
          the server log files if there are concrete indications of illegal use.
        </CopyText>
        <Headline
          level={3}
          text="Handling of Personal Data"
          margin="0 0 1.4rem 0"
        />
        <CopyText margin="0 0 1.5rem 0">
          The website operator only collects, uses and passes on your personal
          data if this is permitted by law or if you consent to the collection
          of data. Personal data is all information that serves to determine
          your person and which can be traced back to you – for example your
          name, your e-mail address and telephone number.
        </CopyText>
        <Headline
          level={3}
          text="Handling of Contact Data"
          margin="0 0 1.4rem 0"
        />{" "}
        <CopyText margin="0 0 1.5rem 0">
          If you get in touch with the website operator or the persons
          organizationally connected to him through the contact options offered,
          your details will be stored so that they can be used for processing
          and responding to your enquiry. This data will not be passed on to
          third parties without your consent.{" "}
        </CopyText>
        <Headline
          margin="0 0 1.4rem 0"
          level={3}
          text="Dealing with Messages"
        />
        <CopyText margin="0 0 1.5rem 0">
          If you leave a message, your IP address and the name and e-mail
          address associated with your user will be stored. This serves the
          identification of the author and the security of the website operator:
          if your text violates the law, he wants to be able to trace your
          identity.
        </CopyText>{" "}
        <Headline
          margin="0 0 1.4rem 0"
          level={3}
          text="Rights of the User: Information, Correction and Deletion"
        />
        <CopyText margin="0 0 1.5rem 0">
          Upon your request, you as a user will receive free information about
          which personal data about you has been stored. Unless your request
          conflicts with a legal obligation to store data (e.g. data retention),
          you have a right to have incorrect data corrected and your personal
          data blocked or deleted.
        </CopyText>
        <Headline margin="0 0 1.4rem 0" level={3} text="Contact Form" />
        <CopyText margin="0 0 1.5rem 0">
          When visitors use the contact form on the site we collect the data
          shown in the form, and also the visitor’s IP address and browser user
          agent string to help spam detection.
        </CopyText>
        <Headline
          margin="0 0 1.4rem 0"
          level={3}
          text="How long we retain your Data"
        />
        <CopyText margin="0 0 1.5rem 0">
          If you contact us via the contact form, the data and its metadata are
          retained indefinitely. This is so we can recognize and approve any
          follow-up messages automatically instead of holding them in a
          moderation queue.
        </CopyText>
        <Headline
          level={3}
          text="What Rights you have over your Data"
          margin="0 0 1.4rem 0"
        />
        <CopyText margin="0 0 1.5rem 0">
          If you contacted us via the contact form, you can request to receive
          an exported file of the personal data we hold about you, including any
          data you have provided to us. You can also request that we erase any
          personal data we hold about you. This does not include any data we are
          obliged to keep for administrative, legal, or security purposes.
        </CopyText>
      </FlexContainer>
    </FlexContainer>
  );
};

export default PrivacyPolicy;
