import styled from "styled-components";

const StyledSliderItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.colors.backgroundDarkTransparent};
  color: ${({ theme }) => theme.colors.primary};
  height: 650px;
  img {
    width: 100%;
    height: 155px;
  }
`;

export default StyledSliderItem;
