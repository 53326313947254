import React from "react";
import CopyText from "../CopyText";
import FlexContainer from "../FlexContainer";
import Headline from "../Headline";

const LegalNotice = () => {
  return (
    <FlexContainer
      direction="column"
      background="backgroundGreyTransparent"
      padding="2rem 1.5rem"
      alignItems="center"
    >
      <FlexContainer maxWidth="1000px" direction="column">
        <Headline level={3} text="Legal Disclaimer" />
        <CopyText>
          For the purposes of this website, UNIICY means the UNIICY Limited
          licensed by the Financial Services Commission in Mauritius and any of
          its subsidiary or associated companies. The information on this
          website is intended for general guidance only and is supplied without
          liability. Users should always seek independent professional advice
          before entering into any contractual relationship. This website is
          intended solely for information purposes. The information materials
          and opinions contained in this website may be altered at any time
          without prior notification.
        </CopyText>

        <Headline
          level={3}
          text="Limitation of Liability for internal content"
        />
        <CopyText>
          The content of our website has been compiled with meticulous care and
          to the best of our knowledge. However, we cannot assume any liability
          for the up-to-dateness, completeness or accuracy of any of the pages.
          We as service providers are liable for our own content on these pages
          in accordance with general laws. However, we as service providers are
          not under obligation to monitor external information provided or
          stored on our website. Once we have become aware of a specific
          infringement of the law, we will immediately remove the content in
          question. Any liability concerning this matter can only be assumed
          from the point in time at which the infringement becomes known to us.
        </CopyText>
        <Headline level={3} text="No Offer" />
        <CopyText>
          The information published on this website represents neither an offer
          nor an invitation to make an offer. Its sole purpose is the
          orientation and description of possible collaborations and
          business-related activities. The information published on this website
          does not assert a claim to completeness and is therefore non-binding.
        </CopyText>
        <Headline level={3} text="No Warranty or Liability" />
        <CopyText>
          Although UNIICY has taken all reasonable care to ensure that the
          information contained in this website is accurate at the time of
          publication, UNIICY provides no assurance or guarantee, expressed or
          implied (including third-party liability), with regard to its
          accuracy, reliability or completeness. The details on this website do
          not represent decision-making aids for legal, fiscal or other
          advice-related questions. UNIICY also gives no guarantee that the
          information may not be distorted as a result of technical malfunctions
          (incorrect transfer, technical inadequacies, disconnection, access by
          an unauthorized third party etc.). In addition, UNIICY makes no
          warranty that that this website is free from viruses or other harmful
          components. Under no circumstances is UNIICY liable for any losses or
          direct, indirect, accidental, special or consequential damages
          resulting from the use or inability to use the information of this
          website or from your access or inability to access to other material
          on the internet via links.
        </CopyText>
        <Headline level={3} text="Copyright" />
        <CopyText>
          The content and works published on this website are governed by the
          copyright laws of Mauritius. Any duplication, processing, distribution
          or any form of utilisation beyond the scope of copyright law shall
          require the prior written consent of the author or authors in
          question. Where the source of images is not expressively mentioned,
          the images have been taken from the private archive of Ivonne Tasche &
          Thorbjörn Tasche. All rights reserved.
        </CopyText>
        <Headline level={3} text="Picture Credits and Copyright" />
        <CopyText>
          Where the source of images is not expressively mentioned, the images
          have been taken from the private archive of Ivonne Tasche and
          Thorbjörn Tasche. All rights reserved.
        </CopyText>
      </FlexContainer>
    </FlexContainer>
  );
};

export default LegalNotice;
