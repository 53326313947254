import React from "react";
import ReactTyped from "react-typed";
import StyledTyped from "./styles";

interface iProps {
  texts: string[];
}

const Typed: React.FC<iProps> = ({ texts }) => {
  return (
    <StyledTyped>
      <ReactTyped
        className="typed-text"
        strings={texts}
        typeSpeed={30}
        backSpeed={20}
        loop
      />
    </StyledTyped>
  );
};

export default Typed;
