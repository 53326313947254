import React from "react";
import ListItem from "../ListItem";
import StyledList from "./styles";

interface iProps {
  items: string[];
}

const List: React.FC<iProps> = ({ items }) => {
  const listItems = items.map((item, index) => (
    <ListItem key={`li-${item}-${index}`}>{item}</ListItem>
  ));
  return <StyledList>{listItems}</StyledList>;
};

export default List;
