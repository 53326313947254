import React, { forwardRef } from "react";
import { ErrorMessage } from "../Contact/ErrorMessage";
import FlexContainer from "../FlexContainer";
import { Errors } from "../Input";
import StyledTextarea from "./styles";
interface TextareaProps extends Omit<JSX.IntrinsicElements["textarea"], "ref"> {
  errors?: Errors;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ errors, ...props }, forwardedRef) => (
    <FlexContainer
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      minHeight="96px"
    >
      <StyledTextarea ref={forwardedRef} {...props} />
      {props?.name && errors?.[props.name]?.message && (
        <ErrorMessage>{errors?.[props.name]?.message}</ErrorMessage>
      )}
    </FlexContainer>
  )
);

Textarea.displayName = "Textarea";

export default Textarea;
