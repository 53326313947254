import { useClickOutside } from "@mantine/hooks";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeNavigationMenuOpen,
  selectNavigationMenuOpen,
} from "../../store/uiState";
import StyledMobileNavMenue from "./styles";

interface Props {
  children: React.ReactNode;
}

const MobileNavMenue: React.FC<Props> = ({ children }) => {
  const navigationMenuOpen = useAppSelector(selectNavigationMenuOpen);
  const dispatch = useAppDispatch();
  const ref = useClickOutside(() => {
    dispatch(closeNavigationMenuOpen());
  });
  return (
    <StyledMobileNavMenue navigationMenuOpen={navigationMenuOpen} ref={ref}>
      {children}
    </StyledMobileNavMenue>
  );
};

export default MobileNavMenue;
