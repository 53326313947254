import React, { useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "../form";
import Input from "../Input";
import Textarea from "../Textarea";
import Button from "../Button";
import ScrollAnchor from "../ScrollAnchor";
import { SuccessMessage } from "./SuccessMessage";
import FlexContainer from "../FlexContainer";
import Headline from "../Headline";
import { Image } from "@mantine/core";
import { useViewport } from "../../hooks/useViewport";

const Contact = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [captcha, setCaptcha] = useState<string | null>("");
  const { isDesktop } = useViewport();

  const { register, handleSubmit, formState, reset } = useForm({
    mode: "onTouched",
  });
  const { errors, isValid } = formState;

  const serviceID = "service_ifzakop";
  const templateID = "template_5i1sptn";
  const userID = "user_ltwsIa8FM3srd8Bo9a0QC";
  const onSubmit: SubmitHandler<FieldValues> = (data, r) => {
    sendEmail(
      serviceID,
      templateID,
      {
        name: data.name,
        phone: data.phone,
        email: data.email,
        subject: data.subject,
        description: data.description,
      },
      userID
    );
    (r?.target as HTMLFormElement)?.reset();
    reset();
  };

  const sendEmail = (
    serviceID: string,
    templateID: string,
    variables: Record<string, unknown> | undefined,
    userID: string | undefined
  ) => {
    emailjs
      .send(serviceID, templateID, variables, userID)
      .then(() =>
        setSuccessMessage(
          "Message sent successfully! We will contact you as soon as possible."
        )
      )
      .catch((err) => console.error(`Something went wrong ${err}`));
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} isDesktop={isDesktop}>
        <ScrollAnchor id="contact" position="absolute" />
        {isDesktop && (
          <FlexContainer maxWidth="350px">
            <Image src="images/ProfileIB.jpg" />
          </FlexContainer>
        )}
        <FlexContainer
          margin={isDesktop ? "0 16px" : ""}
          maxWidth="450px"
          direction="column"
          alignItems="center"
        >
          <Headline level={3} text={"CONTACT US"} />

          <Input
            type="text"
            className="form-control"
            placeholder="Name"
            errors={errors}
            {...register("name", {
              required: "Please enter your name",
              maxLength: {
                value: 20,
                message: "Please enter a name fewer than 20 characters",
              },
            })}
          />

          <Input
            type="tel"
            className="form-control"
            placeholder="Phone"
            errors={errors}
            {...register("phone", {
              required: "Please add your phone number",
            })}
          />

          <Input
            type="text"
            className="form-control"
            placeholder="E-Mail"
            errors={errors}
            {...register("email", {
              required: "Please provide your e-Mail address",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid e-Mail",
              },
            })}
          />

          <Input
            type="text"
            className="form-control"
            placeholder="Subject"
            errors={errors}
            {...register("subject", {
              required: "Please add a subject",
            })}
          />
          <Textarea
            className="form-control"
            placeholder="Please decribe your project."
            errors={errors}
            {...register("description", {
              required: "Please describe shortly your project needs",
            })}
          ></Textarea>

          <ReCAPTCHA
            style={{ display: "inline-block" }}
            theme="dark"
            sitekey="6Legwn0fAAAAALAghJfcjbPGoPDSGxmcd0DRYfV5"
            onChange={(token: string | null) => setCaptcha(token)}
          />
          <SuccessMessage>{successMessage && successMessage}</SuccessMessage>
          <Button
            disabled={!captcha || !isValid}
            className="btn btn-primary"
            type="submit"
          >
            Contact Us
          </Button>
        </FlexContainer>
        {isDesktop && (
          <FlexContainer maxWidth="350px">
            <Image src="images/ProfileTT.jpg" />
          </FlexContainer>
        )}
      </Form>
    </>
  );
};

export default Contact;
