import React, { useState } from "react";
import { useViewport } from "../../hooks/useViewport";
import { Card as CardInterface } from "../../store/sections";
import Card from "../Card";
import FlexContainer from "../FlexContainer";

interface iProps {
  cards: CardInterface[];
}

const Cards: React.FC<iProps> = ({ cards }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { isDesktop } = useViewport();
  const cardItems = cards.map((card, index) => (
    <Card
      {...card}
      key={`card-${card.headline}-${index}`}
      active={activeIndex === index}
      onClick={() => setActiveIndex(activeIndex === index ? null : index)}
    />
  ));
  return (
    <FlexContainer
      justifyContent={isDesktop ? "center" : "flex-start"}
      alignItems="flex-start"
      padding="0"
      flexWrap="wrap"
      alignContent="flex-start"
      margin={isDesktop ? "25px 0 0 0" : ""}
      maxWidth={isDesktop ? "500px" : ""}
    >
      {cardItems}
    </FlexContainer>
  );
};

export default Cards;
