import React from "react";
import CopyText from "../../../CopyText";
import FlexContainer from "../../../FlexContainer";
import StyledFooterSocial from "./styles";
import { useViewport } from "../../../../hooks/useViewport";
import { SocialIcon } from "react-social-icons";

const FooterSocial = () => {
  const { isMobile } = useViewport();
  return (
    <StyledFooterSocial>
      <FlexContainer
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          padding="1.5rem 2rem"
        >
          <FlexContainer width="auto" margin="0 .5rem">
            <SocialIcon
              target="_blank"
              url="https://www.linkedin.com/company/uniicy/?viewAsMember=true"
            />
          </FlexContainer>
          <FlexContainer width="auto" margin="0 .5rem">
            <SocialIcon
              target="_blank"
              url="https://www.instagram.com/uniicy_limited/"
            />
          </FlexContainer>
          <FlexContainer width="auto" margin="0 .5rem">
            <SocialIcon
              target="_blank"
              url="https://twitter.com/UNIICY_LIMITED"
            />
          </FlexContainer>
          <FlexContainer width="auto" margin="0 .5rem">
            <SocialIcon target="_blank" url="https://github.com/Uniicy" />
          </FlexContainer>
        </FlexContainer>
        <CopyText>Made with ♥ in Mauritius</CopyText>
        {isMobile && (
          <>
            <CopyText margin="16px 0 0 0">
              Copyright &copy; {new Date().getFullYear()} UNIICY Limited
            </CopyText>
            <CopyText margin="0 0 16px 0">All Rights Reserved.</CopyText>
          </>
        )}
        {!isMobile && (
          <CopyText>
            Copyright &copy; {new Date().getFullYear()} UNIICY Limited - All
            Rights Reserved.
          </CopyText>
        )}
      </FlexContainer>
    </StyledFooterSocial>
  );
};

export default FooterSocial;
