import styled from "styled-components";
import { LinkProps } from ".";

const StyledLink = styled.a<LinkProps>`
  color: ${({ theme, inverted }) =>
    inverted ? theme.colors.backgroundDarkTransparent : theme.colors.primary};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.colors.primaryLighter};
  }
`;

export default StyledLink;
