import styled, { keyframes } from "styled-components";

const bounce = keyframes`
    20%,50%,80%,to {
        transform: translate(0);
    }
    40% {
        transform: translateY(-15px);
    }
    70% {
        transform: translateY(-10px);
    }
    90% {
        transform: translateY(-2px);
    }
`;

const StyledScrollDownIndicator = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  animation: ${bounce} 2s ease-in infinite;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default StyledScrollDownIndicator;
