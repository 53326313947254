import styled from "styled-components";
import breakpoint from "../../theme/breakpoints";
interface iStyledSection {
  background?: string;
  backgroundPosition?: string;
  height?: string;
  padding?: string;
  name?: string;
}

const StyledSection = styled.div<iStyledSection>`
  background: ${({ background, theme }) =>
    background ? `url(${background})` : theme.colors.primaryDarkbg};
  background-size: cover;
  background-position: ${({ backgroundPosition }) =>
    backgroundPosition ? backgroundPosition : "-100px center"};
  width: 100%;
  height: ${({ height, theme }) =>
    height ? height : `calc(100vh - ${theme.header.height}px)`};
  padding: ${({ padding }) => (padding ? `${padding}` : "")};
  @media (orientation: landscape) {
    display: flex;
    position: relative;
    align-items: center;
    height: auto;
    min-height: ${({ height, theme }) =>
      height ? height : `calc(100vh - ${theme.header.height}px)`};
  }
  @media ${breakpoint.device.m} {
    background-position: center center;
  }
  @media ${breakpoint.device.m} {
    background-position: center center;
  }
  @media ${breakpoint.device.m} {
    display: flex;
    justify-content: center;
  }
`;

export default StyledSection;
