import {
  faCartShopping,
  faDice,
  faHeartPulse,
  faMoneyCheckDollar,
  faPlane,
  faPlaneDeparture,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { createSlice } from "@reduxjs/toolkit";
import { JustifyContent } from "../components/FlexContainer";
import type { RootState } from "./";
import {
  faCode,
  faFeatherAlt,
  faHeart,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import {
  faAngular,
  faAws,
  faDocker,
  faHtml5,
  faJsSquare,
  faPhp,
  faPython,
  faReact,
  faSass,
} from "@fortawesome/free-brands-svg-icons";
import { SectionName } from "./navigationMenu";

export interface Card {
  headline?: string;
  text?: string;
  icon?: IconDefinition;
  icons?: IconDefinition[];
  inverted?: boolean;
  mobileText?: string;
}

export interface SliderItem {
  icon: IconDefinition;
  headline: string;
  text: string;
}

export interface Slider {
  items: SliderItem[];
  headline: string;
}

export interface iSection {
  name?: SectionName;
  headline?: string;
  text?: string | string[];
  background?: string;
  backgroundPosition?: string;
  list?: string[];
  secondText?: string;
  textAlignment?: JustifyContent;
  cards?: Card[];
  slider?: Slider;
  height?: string;
  scrollIndicator?: boolean;
}

// Define a type for the slice state
interface iSections {
  sections: iSection[];
}

// Define the initial state using that type
const initialState: iSections = {
  sections: [
    {
      headline: "IT CONSULTANCY & WEB DEVELOPMENT",
      name: "home",
      text: [
        "Web Development",
        "Software Architecture",
        "Design and Implementation of ICT",
        "Interim technical Management",
      ],
      background: "images/BackgroundImage.jpg",
      backgroundPosition: "-380px center",
    },
    {
      headline: "ABOUT UNIICY",
      name: "about",
      text: `We are Ivonne and Thorbjörn, passionate IT consultants and an
      expat couple, living and working remotely from the tropical
      island Mauritius. Why we moved here as German expats?`,
      cards: [
        {
          headline: "Ingenuity",
          icon: faLightbulb,
          inverted: true,
          text: `As IT Consultants and Web Developer we carry out IT projects
        successfully. By analyzing and identifying our client's
        needs, we propose tailor-made solutions and help our clients
        with the design & implementation.`,
          mobileText: `We analyze & identify our clients needs and propose tailor-made IT solutions.`,
        },
        {
          headline: "Simplicity",
          icon: faFeatherAlt,
          inverted: false,
          text: `Our programming home turf ranges from React, React Native,
          Python, PHP and many more technologies. As genuine
          consultants, we make sure, that we are always up to date
          with the latest and most efficient technologies and methods.`,
          mobileText: `You provide the problem, we provide the solution - it's that simple.`,
        },
        {
          headline: "Value Centricity",
          icon: faHeart,
          inverted: false,
          text: `We are not working 9-5 but until our clients are satisfied.
          We take on responsibility for everything we do and make
          sure, that with the help of our Entrepreneurial Thinking we
          deliver top-notch IT projects.`,
          mobileText: `Responsibility is one important key of our work and our Entrepreneurial Thinking leads to top-notch results.`,
        },
        {
          headline: "Programming Skills",
          icon: faCode,
          inverted: true,
          icons: [
            faReact,
            faJsSquare,
            faAngular,
            faSass,
            faHtml5,
            faAws,
            faDocker,
            faPhp,
            faPython,
          ],
          text: `...and many more`,
          mobileText: `...and many more`,
        },
      ],
      list: [
        "Thriving economy & politcal stability",
        "Direct routes to Europe, Asia and India ",
        "Tropical climate, multicultural population",
      ],
      secondText: `The paradise island Mauritius is located between Africa, Asia
      and the Middle East, making it an international business haven
      and the hub of Africa. Mauritius is ranked as having the 13th
      most free economy of the world - and a number one spot
      globally for investment freedom. A vibrant and prosperous
      country, which makes it for us the perfect place to call our home.`,
    },
    {
      headline: "OUR CORE VALUES",
      name: "values",
      text: `Years of experience shaped our core values and professional skills as IT Conusltants and Web Developer.`,
      background: "images/bulbul.jpg",
      textAlignment: "flex-end",
      scrollIndicator: true,
    },
    {
      textAlignment: "flex-start",
      height: "auto",
      cards: [
        {
          headline: "Ingenuity",
          icon: faLightbulb,
          inverted: true,
          text: `As IT Consultants and Web Developer we carry out IT projects
        successfully. By analyzing and identifying our client's
        needs, we propose tailor-made solutions and help our clients
        with the design & implementation.`,
          mobileText: `We analyze & identify our clients needs and propose tailor-made IT solutions.`,
        },
        {
          headline: "Simplicity",
          icon: faFeatherAlt,
          inverted: false,
          text: `Our programming home turf ranges from React, React Native,
          Python, PHP and many more technologies. As genuine
          consultants, we make sure, that we are always up to date
          with the latest and most efficient technologies and methods.`,
          mobileText: `You provide the problem, we provide the solution - it's that simple.`,
        },
        {
          headline: "Value Centricity",
          icon: faHeart,
          inverted: false,
          text: `We are not working 9-5 but until our clients are satisfied.
          We take on responsibility for everything we do and make
          sure, that with the help of our Entrepreneurial Thinking we
          deliver top-notch IT projects.`,
          mobileText: `Responsibility is key to us and our Entrepreneurial Thinking leads to top-notch results.`,
        },
        {
          headline: "Programming Skills",
          icon: faCode,
          inverted: true,
          icons: [
            faReact,
            faJsSquare,
            faAngular,
            faSass,
            faHtml5,
            faAws,
            faDocker,
            faPhp,
            faPython,
          ],
          text: `...and many more`,
          mobileText: `...and many more`,
        },
      ],
    },
    {
      name: "experience",
      height: "auto",
      slider: {
        headline: "Experience",
        items: [
          {
            headline: "Gambling",
            text: "As Head of Development, Product Owner and Frontend Developer we help our client with a huge tech-stack migration, strategic Analysis of IT-Portfolio and incremental Product-Enhancement. After stabilizing and migrating the legacy tech-stack we help them building a product-vision and roadmap to reach the desired state of their products. Furthermore coaching and implementing of best practices shape the success of this project.",
            icon: faDice,
          },
          {
            headline: "E-Commerce",
            text: "As Senior Frontend Developer / Teamlead we helped our client, one of the biggest electronics distributor in Europe, to refactor their legacy tech-stack to state of the Art technologies and improve their product by implementing new features and a better look and feel. Moreover the backoffice systems were improved to better target the audience with tailormade offers.",
            icon: faCartShopping,
          },
          {
            headline: "Aviation",
            text: `As Senior Full Stack Developer we helped our client
            to build an augmented reality tool to
            support the production workers.
            The tool streamed a video of the production desk onto
            the screen and advised the worker on which parts to 
            assemble together.`,
            icon: faPlane,
          },
          {
            headline: "Fintech",
            text: "As Technical Lead and Product Owner we helped our client to build a whole new Product in the FinTech sector. Products included machine-learning-based trading bots and a visual steering board to monitor, control & report the behaviour of said robots. We helped our client further advancing their data research with a generic platform to look into and validate data in a convenient and fast approach.",
            icon: faMoneyCheckDollar,
          },
          {
            headline: "Airline",
            text: "As Senior Frontend Developer we helped develop and organize the rebrand of a major European Airline. The Rebranding included a complete overhaul of the frontend-tech-stack and was delivered in time and on quality leading to a huge benefit for the customer satisfaction.",
            icon: faPlaneDeparture,
          },

          {
            headline: "Medical-Technology",
            text: "As Frontend Developers we helped to build a new component library from scratch, which then got implemented in all software products of the client. With a heavy focus on visual regression testing and modularity, the project built the foundation for a variety of software projects. The library helped to accelerate development speed and standardization in the whole software department.",
            icon: faHeartPulse,
          },
        ],
      },
    },
  ],
};

export const sectionsSlice = createSlice({
  name: "sections",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
});

//export const { } = navigationMenuSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSections = (state: RootState) => state.sections.sections;

export default sectionsSlice.reducer;
