import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import Icon from "../Icon";
import StyledScrollDownIndicator from "./styles";

const ScrollDownIndicator = () => {
  const [show, setShow] = useState(true);
  const scrollHandler = (
    event: React.SyntheticEvent<HTMLDivElement, AnimationEvent>
  ) => {
    const { target } = event;

    if (
      window.innerHeight -
        (target as HTMLDivElement).getBoundingClientRect().y >
      810
    ) {
      setShow(false);
    }
    if (
      window.innerHeight -
        (target as HTMLDivElement).getBoundingClientRect().y >
      100
    ) {
      setShow(false);
    }
  };
  return (
    <StyledScrollDownIndicator onAnimationIteration={scrollHandler}>
      {show && <Icon icon={faAnglesDown} />}
    </StyledScrollDownIndicator>
  );
};

export default ScrollDownIndicator;
