import breakpoints from "./breakpoints";
import colors from "./colors";

export const theme = {
  colors,
  header: {
    height: 96,
  },
  breakpoints,
};
