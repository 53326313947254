import React from "react";
import { useViewport } from "../../hooks/useViewport";
import { useAppSelector } from "../../store/hooks";
import { selectSections } from "../../store/sections";
import Contact from "../Contact";
import FlexContainer from "../FlexContainer";
import Section from "../Section";
import StyledSections from "./styles";

const Sections: React.FC = () => {
  const sections = useAppSelector(selectSections);
  const { isDesktop } = useViewport();
  return (
    <StyledSections>
      <FlexContainer direction="column">
        {sections
          .filter((section) => !(isDesktop && section.name === "values"))
          .map((section, index) => (
            <Section {...section} key={`section-${index}`} />
          ))}
        <Contact />
      </FlexContainer>
    </StyledSections>
  );
};

export default Sections;
