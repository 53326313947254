import React from "react";
import StyledScrollAnchor from "./styles";

export interface ScrollAnchorProps {
  id: string;
  position?: "absolute";
}

const ScrollAnchor: React.FC<ScrollAnchorProps> = ({ id, ...props }) => {
  return <StyledScrollAnchor id={id} {...props} />;
};

export default ScrollAnchor;
