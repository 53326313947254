import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./";

export type SectionName =
  | "experience"
  | "home"
  | "about"
  | "contact"
  | "values";
export interface iNavigationMenuItem {
  label: string;
  href: SectionName;
  active: boolean;
  inverted?: boolean;
}

// Define a type for the slice state
interface iNavigationMenu {
  items: iNavigationMenuItem[];
}

// Define the initial state using that type
const initialState: iNavigationMenu = {
  items: [
    {
      label: "Home",
      href: "home",
      active: true,
    },
    {
      label: "About",
      href: "about",
      active: false,
    },
    {
      label: "Values",
      href: "values",
      active: false,
    },
    {
      label: "Experience",
      href: "experience",
      active: false,
    },
    {
      label: "Contact",
      href: "contact",
      active: false,
      inverted: true,
    },
  ],
};

export const navigationMenuSlice = createSlice({
  name: "navigationMenu",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
});

//export const { } = navigationMenuSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectNavigationMenuItems = (state: RootState) =>
  state.navigationMenu.items;

export default navigationMenuSlice.reducer;
