import styled, { css } from "styled-components";
import { Headline, iHeadlineProps } from "./Headline";

interface iProps extends iHeadlineProps {
  margin?: string;
  isDesktop?: boolean;
}

const StyledHeadline = styled(Headline)<iProps>`
  color: ${({ theme, inverted }) =>
    inverted ? theme.colors.backgroundDarkTransparent : theme.colors.primary};
  margin-top: 0;
  font-size: ${({ level, isDesktop }) =>
    isDesktop ? ((1.25 * 3) / level) * 2 : (3 / level) * 2}rem;
  font-weight: 500;
  ${({ margin }) => (margin ? `margin: ${margin};` : "")}
  ${({ uppercase }) =>
    uppercase
      ? css`
          text-transform: uppercase;
        `
      : ""}
`;

export default StyledHeadline;
