import styled from "styled-components";

const StyledMobileNavMenue = styled.ul`
  display: flex;
  list-style: none;
  padding: 8px;
  background: ${({ theme }) => theme.colors.primaryDarkbg};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  text-transform: uppercase;
`;

export default StyledMobileNavMenue;
