import React from "react";
import StyledForm from "./styles";

export interface FormProps {
  onSubmit: () => void;
  isDesktop?: boolean;
}

const Form: React.FC<FormProps> = ({ children, onSubmit, ...props }) => (
  <StyledForm onSubmit={onSubmit} {...props}>
    {children}
  </StyledForm>
);

export default Form;
