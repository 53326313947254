import styled from "styled-components";

const StyledBurgerMenue = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.8rem;
  padding: 1rem;
  cursor: pointer;
`;

export default StyledBurgerMenue;
