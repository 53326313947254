import React from "react";
import FlexContainer from "../FlexContainer";
import logo from "../../images/uniicylogo.png";
import StyledHeader from "./styles";
import Logo from "./Logo";
import BurgerMenue from "../BurgerMenue";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import MobileNavMenue from "../MobileNavMenue";
import { selectNavigationMenuItems } from "../../store/navigationMenu";
import { toggleNavigationMenuOpen } from "../../store/uiState";
import NavigationMenuItem from "../NavigationMenuItem";
import { useViewport } from "../../hooks/useViewport";
import NavMenue from "../NavMenue";
import { Link } from "../Link";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const handleMenuClick = () => dispatch(toggleNavigationMenuOpen());
  const navigationMenuItems = useAppSelector(selectNavigationMenuItems);
  const { isMobile, isDesktop } = useViewport();

  const scrollIntoView = (id: string): void => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
    handleMenuClick();
  };

  return (
    <StyledHeader>
      <FlexContainer justifyContent="space-between" alignItems="center">
        <Link href={`/#home`}>
          <Logo src={logo} />{" "}
        </Link>
        {isMobile && <BurgerMenue onClick={handleMenuClick} />}
        {!isMobile && (
          <NavMenue>
            {navigationMenuItems
              .filter((menuItem) => !(isDesktop && menuItem.label === "Values"))
              .map((item, index) => (
                <NavigationMenuItem
                  {...item}
                  key={`navItem-${item.label}-${index}`}
                  onClick={scrollIntoView}
                />
              ))}
          </NavMenue>
        )}
      </FlexContainer>
      {isMobile && (
        <FlexContainer>
          <MobileNavMenue>
            {navigationMenuItems.map((item, index) => (
              <NavigationMenuItem
                {...item}
                key={`navItem-${item.label}-${index + 1}`}
                onClick={scrollIntoView}
              />
            ))}
          </MobileNavMenue>
        </FlexContainer>
      )}
    </StyledHeader>
  );
};

export default Header;
