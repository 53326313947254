import React from "react";

export interface iHeadlineProps
  extends React.HTMLAttributes<HTMLHeadingElement> {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  text: string;
  inverted?: boolean;
  uppercase?: boolean;
  isDesktop?: boolean;
}

export const Headline: React.FC<iHeadlineProps> = ({
  level,
  text,
  ...rest
}) => {
  const HeadlineTag = `h${level}`;
  return <HeadlineTag {...rest}>{text}</HeadlineTag>;
};
