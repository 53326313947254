const colors = {
  primary: "#d8a576",
  primaryLighter: "#ebc19c",
  primaryLightgrey: "#ccd1dc",
  primaryDarkgrey: "#666666",
  primaryBlack: "#000000",
  primaryTransparentBlack: "rgba(0,0,0,0.7)",
  primaryWhite: "#ffffff",
  primaryBlue: "#063057",
  primaryErrorColor: "lightcoral",
  primaryErrorBcolor: "#cf0909",
  primarySuccessColor: "lightgreen",
  primarySuccessBcolor: "#359110",
  primaryDarkbg: "rgb(33,37,41)",
  backgroundLight: "#f4f6f6",
  backgroundLighter: "rgb(50 61 72)",
  backgroundDarkTransparent: "rgb(44 47 50 / 89%);",
  backgroundGreyTransparent: "rgba(54,58,61,1);",
};

export type Colors = typeof colors;

export default colors;
