import styled from "styled-components";

const StyledHeader = styled.div`
  position: fixed;
  width: 100vw;
  height: ${({ theme }) => theme.header.height}px;
  padding: 16px 0.75rem;
  background-color: ${(props) => props.theme.colors.primaryDarkbg};
`;

export default StyledHeader;
