import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import FlexContainer from "./components/FlexContainer";
import Header from "./components/Header";

import { theme } from "./theme/theme";
import store from "./store";
import "./App.css";
import Footer from "./components/Footer";
import Router from "./components/Router";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <FlexContainer direction="column">
          <FlexContainer position="relative" zIndex={2}>
            <Header />
          </FlexContainer>
          <Router />
          <FlexContainer position="relative" zIndex={1}>
            <Footer />
          </FlexContainer>
        </FlexContainer>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
