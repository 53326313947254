import styled from "styled-components";

interface iProps {
  navigationMenuOpen: boolean;
}

const StyledMobileNavMenue = styled.ul<iProps>`
  position: fixed;
  width: 100vw;
  left: 0;
  margin: 0;
  list-style: none;
  padding: 8px;
  background: ${({ theme }) => theme.colors.primaryDarkbg};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  text-transform: uppercase;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: ${(props) => (props.navigationMenuOpen ? 270 : 0)}px;
`;

export default StyledMobileNavMenue;
