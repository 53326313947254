import styled from "styled-components";

const StyledButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.backgroundDarkTransparent};
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
  cursor: pointer;
  border: none;
  margin-top: 1rem;
  width: 100%;
  text-transform: uppercase;
  :disabled {
    background-color: #d8a57669;
  }
`;

export default StyledButton;
