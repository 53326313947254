import React from "react";
import SlickSlider from "react-slick";
import FlexContainer from "../FlexContainer";
import Headline from "../Headline";
import { Slider as SliderProps } from "../../store/sections";
import SliderItem from "../SliderItem";
import { useViewport } from "../../hooks/useViewport";

const Slider: React.FC<SliderProps> = ({ items, headline }) => {
  const { isTablet, isDesktop } = useViewport();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isTablet || isDesktop,
  };
  const renderItems = items.map((item, index) => (
    <SliderItem {...item} key={`item-${item}-${index}`} />
  ));
  return (
    <FlexContainer direction="column" padding="2rem">
      <Headline level={3} text={headline} inverted={false} uppercase />
      <SlickSlider {...settings}>{renderItems}</SlickSlider>
    </FlexContainer>
  );
};

export default Slider;
