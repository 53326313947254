import React from "react";
import StyledNavMenue from "./styles";

interface Props {
  children: React.ReactNode;
}

const NavMenue: React.FC<Props> = ({ children }) => {
  return <StyledNavMenue>{children}</StyledNavMenue>;
};

export default NavMenue;
