import React from "react";
import styled, { css } from "styled-components";

interface Props extends React.ComponentPropsWithoutRef<"p"> {
  inverted?: boolean;
  fontSize?: string;
  margin?: string;
  upperCase?: boolean;
  textAlign?: "justify" | "left" | "right" | "center" | "start";
}

const CopyText = styled.p<Props>`
  color: ${({ theme, inverted }) =>
    inverted ? theme.colors.backgroundDarkTransparent : theme.colors.primary};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "justify")};
  line-height: 1.25rem;
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `}
  ${({ margin }) =>
    typeof margin !== undefined &&
    css`
      margin: ${margin};
    `}
    ${({ upperCase }) =>
    upperCase &&
    css`
      text-transform: uppercase;
    `}
`;

export default CopyText;
