import React from "react";
import Particles from "react-tsparticles";
import config from "./Particles.config";
import { loadFull } from "tsparticles";
import styled from "styled-components";
import { Engine } from "tsparticles-engine";

interface iProps {
  className?: string;
}

const StyledParticles = styled(Particles)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const ParticlesContainer: React.FC<iProps> = ({ className }) => {
  const particlesInit = async (main: Engine) => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  return (
    <>
      <StyledParticles
        id="tsparticles"
        init={particlesInit}
        options={config}
        className={className}
      />
    </>
  );
};

export default ParticlesContainer;
