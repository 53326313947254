import styled, { css } from "styled-components";
import { ScrollAnchorProps } from ".";

const StyledScrollAnchor = styled.div<ScrollAnchorProps>`
  ${({ position }) =>
    position
      ? css`
          position: absolute;
          top: -${({ theme }) => theme.header.height}px;
        `
      : css`
          margin-top: -${({ theme }) => theme.header.height}px;
        `}
  margin-bottom: ${({ theme }) => theme.header.height}px;
  @media (orientation: landscape) {
    position: absolute;
    top: 0;
  }
`;

export default StyledScrollAnchor;
