import {
  faPhoneSquareAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useViewport } from "../../../../hooks/useViewport";
import CopyText from "../../../CopyText";
import FlexContainer from "../../../FlexContainer";
import { Link } from "../../../Link";
import StyledFooterAddress from "./styles";

export const FooterAddress = () => {
  const { isDesktop } = useViewport();
  return (
    <StyledFooterAddress>
      <FlexContainer justifyContent="center">
        <CopyText>UNIICY Limited</CopyText>
      </FlexContainer>
      <FlexContainer
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <CopyText margin="0">Silicon Avenue 40 | Cybercity</CopyText>
        <CopyText margin="0 0 1.5rem 0">72201 Ebène | Mauritius</CopyText>
        {!isDesktop && (
          <>
            <Link href="tel:+23058485475">
              <FontAwesomeIcon icon={faPhoneSquareAlt} /> +230 5848-5475
            </Link>
            <Link href="tel:+491717840410">
              <FontAwesomeIcon icon={faPhoneSquareAlt} /> +49 171 78-40-410
            </Link>
          </>
        )}
        <Link href="mailto:info@uniicy.com">
          <FontAwesomeIcon icon={faEnvelope} /> info@uniicy.com
        </Link>
      </FlexContainer>
    </StyledFooterAddress>
  );
};
