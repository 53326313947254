import styled from "styled-components";

const StyledFooterLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  margin-bottom: 1rem;
  @media ${({ theme }) => theme.breakpoints.device.l} {
    flex-direction: column;
    align-self: center;
  }
`;

export default StyledFooterLinks;
