import React from "react";
import { Routes, Route } from "react-router-dom";
import FlexContainer from "../FlexContainer";
import Imprint from "../Imprint";
import LegalNotice from "../LegalNotice";
import PrivacyPolicy from "../PrivacyPolicy";
import Sections from "../Sections";
import StyledSections from "../Sections/styles";

const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <FlexContainer position="relative" zIndex={1} justifyContent="center">
            <Sections />
          </FlexContainer>
        }
      />
      <Route
        path="/privacy"
        element={
          <FlexContainer position="relative" zIndex={1}>
            <StyledSections>
              <PrivacyPolicy />
            </StyledSections>
          </FlexContainer>
        }
      />
      <Route
        path="/imprint"
        element={
          <FlexContainer position="relative" zIndex={1}>
            <StyledSections>
              <Imprint />
            </StyledSections>
          </FlexContainer>
        }
      />
      <Route
        path="/legal"
        element={
          <FlexContainer position="relative" zIndex={1}>
            <StyledSections>
              <LegalNotice />
            </StyledSections>
          </FlexContainer>
        }
      />
    </Routes>
  );
};

export default Router;
