import React from "react";
import StyledLink from "./styles";

export interface LinkProps extends React.ComponentPropsWithoutRef<"a"> {
  inverted?: boolean;
}

export const Link: React.FC<LinkProps> = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>;
};
