import styled from "styled-components";
import FlexContainer, { FlexContainerProps } from "../FlexContainer";
import breakpoint from "../../theme/breakpoints";

interface iProps extends FlexContainerProps {
  inverted?: boolean;
}

const StyledCard = styled(FlexContainer)<iProps>`
  background: ${({ theme, inverted }) =>
    inverted ? theme.colors.primary : theme.colors.backgroundDarkTransparent};
  padding: 1rem;
  width: 50vw;
  height: 50vw;
  flex-direction: column;
  color: ${({ theme, inverted }) =>
    inverted ? theme.colors.backgroundDarkTransparent : theme.colors.primary};
  flex-wrap: nowrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  @media ${breakpoint.device.l} {
    width: 200px;
    height: 200px;
  }
`;

export default StyledCard;
