import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./";

// Define a type for the slice state
interface UiState {
  navigationMenuOpen: boolean;
}

// Define the initial state using that type
const initialState: UiState = {
  navigationMenuOpen: false,
};

export const uiStateSlice = createSlice({
  name: "uiState",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleNavigationMenuOpen: (state) => {
      state.navigationMenuOpen = !state.navigationMenuOpen;
    },
    closeNavigationMenuOpen: (state) => {
      state.navigationMenuOpen = false;
    },
  },
});

export const { toggleNavigationMenuOpen, closeNavigationMenuOpen } =
  uiStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectNavigationMenuOpen = (state: RootState) =>
  state.uiState.navigationMenuOpen;

export default uiStateSlice.reducer;
