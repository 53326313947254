import styled from "styled-components";
import breakpoints from "../../theme/breakpoints";

const StyledTyped = styled.div`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  text-align: left;
  @media ${breakpoints.device.m} {
    max-width: 800px;
  }
`;

export default StyledTyped;
