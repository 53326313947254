import React, { forwardRef } from "react";
import { ErrorMessage } from "../Contact/ErrorMessage";
import FlexContainer from "../FlexContainer";
import StyledInput from "./styles";

export interface Error {
  message: string;
}
export interface Errors {
  [x: string]: Error;
}
interface InputProps extends Omit<JSX.IntrinsicElements["input"], "ref"> {
  errors?: Errors;
  name?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ errors, ...props }, forwardedRef) => {
    return (
      <FlexContainer
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        minHeight="60px"
      >
        <StyledInput ref={forwardedRef} {...props} />
        {props?.name && errors?.[props.name]?.message && (
          <ErrorMessage>{errors?.[props.name]?.message}</ErrorMessage>
        )}
      </FlexContainer>
    );
  }
);

Input.displayName = "Input";
export default Input;
