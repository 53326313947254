import React from "react";
import { useViewport } from "../../hooks/useViewport";
import { FooterAddress } from "./components/FooterAddress";
import FooterLinks from "./components/FooterLinks";
import FooterSocial from "./components/FooterSocial";
import StyledFooter from "./styles";

const Footer = () => {
  const { isDesktop } = useViewport();
  return (
    <StyledFooter>
      <FooterAddress />
      {isDesktop ? <FooterSocial /> : <FooterLinks />}
      {isDesktop ? <FooterLinks /> : <FooterSocial />}
    </StyledFooter>
  );
};

export default Footer;
