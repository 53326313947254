import styled from "styled-components";
import { FormProps } from ".";

const StyledForm = styled.form<FormProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundDarkTransparent};
  padding: 2rem;
  position: relative;
  justify-content: ${({ isDesktop }) =>
    isDesktop ? "space-around" : "center"};
  align-items: center;
  @media (orientation: landscape) {
    position: relative;
  }
`;

export default StyledForm;
