import React from "react";
import { SliderItem as SliderItemProps } from "../../store/sections";
import CopyText from "../CopyText";
import FlexContainer from "../FlexContainer";
import Headline from "../Headline/";
import Icon from "../Icon";
import StyledSliderItem from "./styles";

const SliderItem: React.FC<SliderItemProps> = ({ headline, text, icon }) => {
  return (
    <StyledSliderItem>
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        fontSize="85px"
        flexGrow={0}
        flexShrink={0}
        height="224px"
      >
        <Icon icon={icon} />
      </FlexContainer>
      <FlexContainer padding="2rem" direction="column">
        <Headline level={5} text={headline} uppercase />
        <CopyText>{text}</CopyText>
      </FlexContainer>
    </StyledSliderItem>
  );
};

export default SliderItem;
