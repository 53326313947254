import React from "react";
import { useViewport } from "../../hooks/useViewport";
import { Card as CardProps } from "../../store/sections";
import CopyText from "../CopyText";
import FlexContainer from "../FlexContainer";
import Headline from "../Headline";
import Icon from "../Icon";
import StyledCard from "./styles";

interface iProps extends CardProps {
  active: boolean;
  onClick: () => void;
}

const Card: React.FC<iProps> = ({
  headline,
  icon,
  text,
  active,
  onClick,
  icons,
  inverted,
  mobileText,
}) => {
  const inlineIcons = icons?.map((icon, index, arr) => {
    const len = arr.length;
    const flexBasis = Math.ceil(Math.sqrt(len));
    return (
      <FlexContainer
        key={`inline-icon-${index}`}
        justifyContent="center"
        height="auto"
        flexBasis={`${100 / flexBasis}%`}
      >
        <Icon key={`${text}-${index}`} icon={icon} />
      </FlexContainer>
    );
  });
  const { isDesktop, isMini } = useViewport();
  return (
    <StyledCard
      onClick={onClick}
      onMouseEnter={isDesktop ? onClick : () => null}
      justifyContent="center"
      alignItems="center"
      inverted={inverted}
    >
      {icon && !active && <Icon icon={icon} />}
      {headline && !active && (
        <Headline
          level={isMini ? 4 : 3}
          text={headline}
          inverted={inverted}
          margin="1rem 0 0 0"
        />
      )}
      {icons && active && (
        <FlexContainer
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          {inlineIcons}
        </FlexContainer>
      )}
      {((text && isDesktop) || (mobileText && !isDesktop)) && active && (
        <CopyText fontSize="1rem" inverted={inverted} textAlign="start">
          {mobileText}
        </CopyText>
      )}
    </StyledCard>
  );
};

export default Card;
