import styled, { css } from "styled-components";

interface iProps {
  inverted?: boolean;
}

const StyledNavigationMenuItem = styled.li<iProps>`
  padding: 8px;
  margin: 16px;
  cursor: pointer;
  ${({ inverted, theme }) =>
    inverted
      ? css`
          background-color: ${theme.colors.primary};
          color: ${theme.colors.primaryDarkbg};
          border-radius: 4px;
        `
      : ""}
`;

export default StyledNavigationMenuItem;
