import React from "react";
import { useViewport } from "../../../../hooks/useViewport";
import CopyText from "../../../CopyText";
import FlexContainer from "../../../FlexContainer";
import { Link } from "../../../Link";
import StyledFooterLinks from "./styles";

const FooterLinks = () => {
  const { isDesktop, isMini } = useViewport();
  return (
    <StyledFooterLinks>
      <FlexContainer width="auto" margin={isMini ? "0.15rem" : ".5rem"}>
        {isDesktop ? (
          <Link href="/imprint">Imprint</Link>
        ) : (
          <Link href="/privacy">Privacy Policy</Link>
        )}
      </FlexContainer>
      {!isDesktop && (
        <FlexContainer width="auto" margin={isMini ? "0.15rem" : ".5rem"}>
          <CopyText margin="0">|</CopyText>
        </FlexContainer>
      )}
      <FlexContainer width="auto" margin={isMini ? "0.15rem" : ".5rem"}>
        {isDesktop ? (
          <Link href="/privacy">Privacy Policy</Link>
        ) : (
          <Link href="/imprint">Imprint</Link>
        )}
      </FlexContainer>
      {!isDesktop && (
        <FlexContainer width="auto" margin={isMini ? "0.15rem" : ".5rem"}>
          <CopyText margin="0">|</CopyText>
        </FlexContainer>
      )}
      <FlexContainer width="auto" margin={isMini ? "0.15rem" : ".5rem"}>
        <Link href="/legal">Legal Notice</Link>
      </FlexContainer>
    </StyledFooterLinks>
  );
};

export default FooterLinks;
