import React from "react";
import CopyText from "../CopyText";
import FlexContainer from "../FlexContainer";
import Headline from "../Headline";
import { Wrapper } from "@googlemaps/react-wrapper";
import Map from "../Map";
import { Marker } from "../Map/Marker";

const Imprint = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {});
      setMap(newMap);
    }
  }, [ref, map]);
  const copyTextMargin = "0 0 .1rem 0";
  const latlng = { lat: -20.2416252, lng: 57.495 };
  return (
    <FlexContainer
      direction="column"
      justifyContent="center"
      alignItems="center"
      background="backgroundGreyTransparent"
      padding="2rem 1.5rem"
    >
      <Headline level={3} text="Who we are" margin="0 0 2rem 0" />
      <FlexContainer
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <CopyText margin="0 0 1rem 0">UNIICY Limited </CopyText>
        <CopyText margin={copyTextMargin}>
          The Cyberati Lounge, Ground Floor
        </CopyText>
        <CopyText margin={copyTextMargin}>The Catalyst</CopyText>
        <CopyText margin={copyTextMargin}>
          Silicon Avenue 40, Cybercity{" "}
        </CopyText>
        <CopyText margin={copyTextMargin}>72201 Ebène</CopyText>
        <CopyText margin="0 0 1rem 0">Mauritius</CopyText>
        <CopyText margin={copyTextMargin}>E-mail: info@uniicy.com</CopyText>
        <CopyText margin={copyTextMargin}>
          Telephone Number: +230 584 854 75
        </CopyText>
        <CopyText margin={copyTextMargin}>
          Company Registration Number: 183980/GBC
        </CopyText>
        <CopyText margin="0 0 1rem 0">Licence No: GB21200103</CopyText>
        <CopyText margin="0 0 1rem 0">Authorized Persons:</CopyText>
        <CopyText margin={copyTextMargin}>Ivonne Tasche, CEO</CopyText>
        <CopyText margin={copyTextMargin}>&</CopyText>
        <CopyText margin="0 0 2rem 0">Thorbjörn Tasche, Director</CopyText>
        <CopyText margin={copyTextMargin}></CopyText>
      </FlexContainer>
      <FlexContainer maxWidth="888px">
        <Wrapper apiKey={"AIzaSyDNqLWNuXitMCLeR9oss3pTwFfLP5CpnHM"}>
          <Map
            style={{ height: "500px", width: "100%" }}
            center={latlng}
            zoom={16}
          >
            <Marker position={latlng} label="UNIICY Limited" />
          </Map>
        </Wrapper>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Imprint;
