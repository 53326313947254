import styled from "styled-components";

const StyledTextarea = styled.textarea`
  border: none;
  border-bottom: 1px solid black;
  line-height: 2rem;
  margin-bottom: 0;
  background: ${({ theme }) => theme.colors.backgroundGreyTransparent};
  padding: 0.25rem;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  ::placeholder {
    color: ${({ theme }) => theme.colors.primary};
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export default StyledTextarea;
