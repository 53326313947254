const size = {
  xs: `0px`,
  s: "400px",
  m: `675px`,
  l: `1200px`,
  xl: `2100px`,
  xxl: `2165px`,
  xxxl: "2370px",
};
const device = {
  xs: `(min-width: ${size.xs})`,
  s: `(min-width: ${size.s})`,
  m: `(min-width: ${size.m})`,
  l: `(min-width: ${size.l})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
  xxxl: `(min-width: ${size.xxxl})`,
};

const breakpoints = { size, device };

export default breakpoints;
