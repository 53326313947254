import React from "react";
import { Link } from "../Link";
import { iNavigationMenuItem } from "../../store/navigationMenu";
import StyledNavigationMenuItem from "./styles";

interface iProps extends iNavigationMenuItem {
  onClick: (href: string) => void;
}

const NavigationMenuItem: React.FC<iProps> = ({
  label,
  href,
  onClick,
  inverted,
}) => {
  return (
    <StyledNavigationMenuItem inverted={inverted} onClick={() => onClick(href)}>
      <Link href={`/#${href}`} inverted={inverted}>
        {label}
      </Link>
    </StyledNavigationMenuItem>
  );
};

export default NavigationMenuItem;
