import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import StyledBurgerMenue from "./styles";

interface iProps {
  onClick?: () => void;
}

const BurgerMenue: React.FC<iProps> = ({ onClick }) => {
  return (
    <StyledBurgerMenue onClick={onClick}>
      <FontAwesomeIcon icon={faBars} />
    </StyledBurgerMenue>
  );
};

export default BurgerMenue;
