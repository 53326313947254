import styled, { css } from "styled-components";
import { Colors } from "../../theme/colors";

export type JustifyContent =
  | "space-between"
  | "flex-start"
  | "center"
  | "normal"
  | "flex-end";
export interface FlexContainerProps {
  direction?: "row" | "column";
  justifyContent?: JustifyContent;
  alignItems?: "center" | "normal" | "flex-start";
  background?: keyof Colors | null;
  height?: string;
  padding?: string;
  flexWrap?: "wrap" | "nowrap";
  alignContent?: "stretch" | "flex-start";
  flexBasis?: string;
  position?: string;
  zIndex?: number;
  fontSize?: string;
  flexGrow?: number;
  flexShrink?: number;
  margin?: string;
  minHeight?: string;
  width?: string;
  maxWidth?: string;
  flex?: string;
}

const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  height: ${({ height }) => (height ? `${height}` : "100%")};
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justifyContent || "normal"};
  align-items: ${(props) => props.alignItems || "normal"};
  ${({ background, theme }) =>
    background &&
    css`
      background: ${theme.colors[background]};
    `}
  ${({ width = "100%" }) =>
    width &&
    css`
      width: ${width};
    `}
    ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
    ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
    ${({ margin }) =>
    typeof margin !== undefined &&
    css`
      margin: ${margin};
    `}
    ${({ flexBasis }) =>
    flexBasis &&
    css`
      flex-basis: ${flexBasis};
    `}

    ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
    ${({ alignContent }) =>
    alignContent &&
    css`
      align-content: ${alignContent};
    `}
    ${({ position }) =>
    position &&
    css`
      position: ${position};
    `}
    ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}
    ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `}
    ${({ flexGrow }) =>
    typeof flexGrow !== undefined &&
    css`
      flex-grow: ${flexGrow};
    `}
    ${({ flexShrink }) =>
    typeof flexShrink !== undefined &&
    css`
      flex-shrink: ${flexShrink};
    `}
    ${({ minHeight }) =>
    typeof minHeight !== undefined &&
    css`
      min-height: ${minHeight};
    `}
`;

export default FlexContainer;
