import React from "react";

import styled from "styled-components";
import { iSection } from "../../store/sections";
import Cards from "../Cards";
import CopyText from "../CopyText";
import FlexContainer from "../FlexContainer";
import Headline from "../Headline";
import List from "../List";
import Typed from "../Typed";
import Slider from "../Slider";
import StyledSection from "./styles";
import ScrollAnchor from "../ScrollAnchor";
import ScrollDownIndicator from "../ScrollDownIndicator";
import { useViewport } from "../../hooks/useViewport";
import Particles from "../Particles";

const Svg = styled.svg`
  background: url("/images/bulbul.jpg");
  background-size: cover;
  width: 650px;
  height: 850px;
  clip-path: polygon(0 0, 100% 0, 65% 100%, 0 100%);
  flex-grow: 0;
  flex-shrink: 0;
`;

const Svg2 = styled.svg`
  background: url("/images/theway.jpg");
  background-size: cover;
  width: 650px;
  height: 850px;
  flex-grow: 0;
  flex-shrink: 0;
  clip-path: polygon(35% 0, 100% 0, 100% 100%, 0 100%);
`;

const Section: React.FC<iSection> = ({
  headline,
  text,
  background,
  list,
  secondText,
  textAlignment,
  cards,
  slider,
  name,
  height,
  scrollIndicator,
  backgroundPosition,
}) => {
  const { isDesktop, isXXL, isTablet } = useViewport();
  const isAboutSection = name === "about";
  const isAboutAndDesktop = isDesktop && isAboutSection;
  const isAboutAndXXL = isXXL && isAboutSection;
  return (
    <StyledSection
      background={background}
      height={height}
      backgroundPosition={backgroundPosition}
      className="particles"
      padding={isAboutAndDesktop ? "64px 0" : ""}
    >
      {name === "experience" && isDesktop && (
        <Particles className="particles" />
      )}
      {name && <ScrollAnchor id={name} />}
      <FlexContainer
        direction={isAboutAndDesktop ? "row" : "column"}
        alignItems={isAboutAndDesktop ? "center" : undefined}
        justifyContent={
          isAboutAndDesktop ? "space-between" : textAlignment || "center"
        }
        maxWidth={
          (isDesktop || isTablet) && name === "experience" ? "600px" : ""
        }
      >
        {cards && !isDesktop && !isAboutSection && (
          <FlexContainer
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <FlexContainer
              maxWidth={isDesktop ? "600px" : ""}
              justifyContent="center"
              alignItems="center"
            >
              <Cards cards={cards} />
            </FlexContainer>
          </FlexContainer>
        )}
        {slider && <Slider {...slider} />}
        {isAboutAndDesktop && <Svg />}
        <FlexContainer
          direction="column"
          justifyContent="center"
          background={background ? "backgroundDarkTransparent" : null}
          height="auto"
          padding="2rem"
          width={isAboutAndDesktop ? "" : "auto"}
          maxWidth={isAboutAndDesktop ? "" : "800px"}
          flex={isAboutAndDesktop ? "1 1 auto" : ""}
        >
          {headline && (
            <Headline
              level={3}
              text={headline}
              isDesktop={isDesktop}
              margin={isAboutAndDesktop ? "0" : ""}
            />
          )}
          {typeof text === "string" && <CopyText>{text}</CopyText>}
          {Array.isArray(text) && <Typed texts={text} />}
          {list && <List items={list} />}
          {secondText && <CopyText>{secondText}</CopyText>}
          {isAboutAndDesktop && cards && (
            <FlexContainer justifyContent="center" alignItems="center">
              <Cards cards={cards} />
            </FlexContainer>
          )}

          {scrollIndicator && <ScrollDownIndicator />}
        </FlexContainer>
        {isAboutAndXXL && <Svg2 />}
      </FlexContainer>
    </StyledSection>
  );
};

export default Section;
