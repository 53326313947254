import { useMediaQuery } from "@mantine/hooks";

export const useViewport = () => {
  const isMobile = useMediaQuery("(max-width: 1000px)");
  const isMini = useMediaQuery("(max-width: 377px)");
  const isTablet = useMediaQuery("(min-width: 1001px) and (max-width: 1199px)");
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const isAboutSpecial = useMediaQuery("(min-width: 1515px)");
  const isXXL = useMediaQuery("(min-width: 1815px)");
  const isLandscape = useMediaQuery("(orientation: landscape)");

  return {
    isMobile,
    isDesktop,
    isTablet,
    isLandscape,
    isXXL,
    isAboutSpecial,
    isMini,
  };
};
